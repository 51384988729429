<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <div>
                    Pagina niet gevonden. Is er iets niet goed? Neem dan contact op met
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <a
                            style="color:#76baff"
                            target="_blank"
                            href="mailto:service@roadpursuit.com"
                            @click.stop
                            v-on="on">
                            service@roadpursuit.com
                        </a>
                        </template>
                        Opent uw mail app
                    </v-tooltip>
                    .
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: '404Page',
    data: () => ({
     
    }),
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
    },
    mixins: [MetaTagsMixin],
    metaInfo() {
        return {
        title: '| Pagina niet gevonden',
        meta: [
            this.getKeywords(`404`),
            this.getMetaDescription(`404 pagina niet gevonden`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`404 pagina niet gevonden`, `404`,  '', ''),
        ]
        };
    },
}
</script>
